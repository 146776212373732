import React from 'react'
import { useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './tablev3.css'
import '../Home/classess.css'
import { IHistorySession } from './IDashboard';
import { getMergedEditorContent, mergeSessions } from '../../api/SessionsService';
import { convertFromRaw, EditorState } from 'draft-js';
import NewTable from './NewTable';
import useDownload from '../../hooks/useDownload';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from 'luxon';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setHistoryDate } from '../../redux/features/app/app';


const V3Table = () => {
    const [data, setData] = useState<IHistorySession[]>([]);
    const startDate = useAppSelector(state => state.app.historyDate);
    const dispatch = useAppDispatch();

    
    const onDateChange = (date: Date | null) => {
        if (!date) return;
        dispatch(setHistoryDate(date))
    }

    const { enqueueSnackbar } = useSnackbar();

    const { handleDownloadDocxFromState } = useDownload();

    const updateData = (newData: IHistorySession[]) => setData(newData);

    const mergeContentAndDownload = async (prefix: string) => {
        
        const mergeSessionIds: number[] = []
        const prefixedSessions = data.filter(session => session.name.startsWith(prefix) && !session.isHeader)
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            if (session.versionTags.length > 0) {
                mergeSessionIds.push(session.id)
            } else {
                break;
            }
        }

        if (mergeSessionIds.length < 1) {
            enqueueSnackbar("Ena ali več sej ne zadoščajo pogojem za skupni izvoz.", { variant: 'error' })
            return;
        }

        const parameters = new URLSearchParams();

        mergeSessionIds.forEach(id => {
            parameters.append("session-id", id.toString())
        })
        
        try {
            const test = await getMergedEditorContent(parameters);
            const notRaw = convertFromRaw(test.data.rawContentState)
            const editorState = EditorState.createWithContent(notRaw)
            await handleDownloadDocxFromState(editorState)
        } catch (error) {
            console.log(error)
        }
    }

    const mergePrefixContent = async (prefix: string) => {

        const mergeSessionIds: number[] = []
        const prefixedSessions = data.filter(session => session.name.startsWith(prefix) && !session.isHeader)
        let name = ""
        for (let sessionIndex = 0; sessionIndex < prefixedSessions.length; sessionIndex++) {
            const session = prefixedSessions[sessionIndex]
            
            if (sessionIndex === 0) {
                name = session.name
            }

            const sessionHasLabel = session.labels.filter(label => label.label.code === "Končano" && label.label.isAssigned).length === 1
            if (session.versionTags.length > 0 && sessionHasLabel) {
                mergeSessionIds.push(session.id)
            } else {
                break;
            }
        }

        if (mergeSessionIds.length < 1) {
            enqueueSnackbar("Ena ali več sej ne zadoščajo pogojem za združevanje.", { variant: 'error' })
            return;
        }

        try {
            await mergeSessions(mergeSessionIds, name);
        } catch (error) {
            console.log(error)
        }
    }


    const onChangeRaw = (event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => {
        console.log(event)
    }


    const addDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).plus({day: 1}).toISO()
        onDateChange(new Date(newDate as string))
    }

    const subtractDay = () => {
        const newDate = DateTime.fromISO(startDate.toISOString()).minus({day: 1}).toISO()
        onDateChange(new Date(newDate as string))
    }

    return (
        <div>
            <div id="date-picker-container">
                <div style={{cursor: 'pointer'}} onClick={subtractDay}><ArrowLeft /></div>
                <DatePicker selected={startDate} className='date_input' value={`${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}`} onChangeRaw={onChangeRaw} onChange={onDateChange} />
                <div style={{cursor: 'pointer'}} onClick={addDay}><ArrowRight /></div>
            </div>
            {/*<Button onClick={joinTaggedSessions}>JOIN SESSIONS</Button>
            <Button onClick={mergeContentAndDownload}>JOIN CONTENT</Button>*/}
            <DndProvider backend={HTML5Backend}>
                <NewTable mergeContentAndDownload={mergeContentAndDownload} mergePrefixContent={mergePrefixContent} selectedDate={startDate} updateData={updateData} data={data} />
            </DndProvider>
        </div>
    )
}

export default V3Table;